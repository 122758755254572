import { makeAutoObservable } from 'mobx'

export class MapStore {
	width = 15000
	height = 15000

	scale: number = 0.4
	translateX: number = 470
	translateY: number = 300

	constructor() {
		makeAutoObservable(this)
	}

	setScale(newScale: number) {
		this.scale = newScale
	}

	setTranslate(x: number, y: number) {
		this.translateX = x
		this.translateY = y
	}

	updateTranslate(dx: number, dy: number) {
		this.translateX += dx
		this.translateY += dy
	}
}
