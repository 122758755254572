import { IDefualtObjectProps } from '../types/object.types'
import { IObjectText, TEXT_DEFUALT } from '../types/text'
import { action, makeObservable, observable } from 'mobx'

export class ObjectStore {
	id: number
	text: IObjectText
	x: number = 100
	y: number = 100
	width: number = 100
	height: number = 100
	zIndex: number = 0
	selected: boolean = false
	fill: string = 'none'

	constructor({
		id,
		x = 100,
		y = 100,
		width = 100,
		height = 100,
		selected = false,
		zIndex = 0,
		fill,
		text
	}: IDefualtObjectProps) {
		this.id = id
		this.x = x
		this.y = y
		this.width = width
		this.height = height
		this.selected = selected
		this.zIndex = zIndex
		this.fill = fill || 'none'
		this.text = text || TEXT_DEFUALT

		makeObservable(this, {
			x: observable,
			y: observable,
			width: observable,
			height: observable,
			zIndex: observable,
			text: observable,
			fill: observable,
			selected: observable,
			setPosition: action,
			updatePosition: action,
			setSize: action,
			setZIndex: action,
			setSelected: action
		})
	}

	setPosition(x: number, y: number) {
		this.x = x
		this.y = y
	}

	updatePosition(dx: number, dy: number) {
		this.x += dx
		this.y += dy
	}

	setSize(width: number, height: number) {
		this.width = width
		this.height = height
	}

	setZIndex(z: number) {
		this.zIndex = z
	}

	setSelected(selected: boolean) {
		this.selected = selected
	}
}
