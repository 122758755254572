import {
	IPostDtoRouteMap,
	IPutDtoRouteMap,
	IPutDtoRouteMapList,
	IRouteMap,
	IRouteMapHistoryParams,
	IRouteMapHistoryResponse,
	IRouteMapParams,
	IRoutemapWithPagination,
	IRoutemapWithPaginationItem
} from '../types/Routemap.types'
import { $authHost } from '@/API'

class RoutemapService {
	getAll = (params?: IRouteMapParams) => {
		return $authHost.get<IRoutemapWithPagination>('api/routemap/', {
			params: {
				page: params?.page,
				is_active: params?.is_active,
				exact: params?.exact,
				report_number: params?.report_number,
				all: params?.all ? params?.all : undefined,
				tech_id: params?.tech_id?.length ? params?.tech_id?.toString() : null,
				pickstate:
					params?.pickstate || params?.pickstate === 0
						? params?.pickstate
						: null,
				order_state: params?.order_state || undefined,
				spec_id: params?.spec_id || undefined,
				status: params?.status_id?.length
					? params?.status_id?.toString()
					: null,
				// если empl_id нет - undefined
				// если empl_id массив - приводим к строек
				// если empl_id не массив - вставляем как есть
				// иначе ничего не вставлем
				empl_id: params?.empl_id
					? Array.isArray(params?.empl_id)
						? params?.empl_id?.length
							? params?.empl_id?.toString()
							: undefined
						: params?.empl_id
					: undefined,
				production_task_id: params?.production_task_id?.length
					? params?.production_task_id?.toString()
					: null,

				date_from: params?.dateFrom ? params.dateFrom : undefined,
				date_to: params?.dateTo ? params.dateTo : undefined,
				id_operation: params?.idOperation,
				sb_num_part: params?.sbNumPart,
				incanline: params?.incanline,
				manual_load: params?.manual_load,
				executing_programm_num: params?.executing_programm_num
					? params?.executing_programm_num
					: undefined,
				department_id: params?.department_id?.length
					? params?.department_id?.toString()
					: null,
				packing: params?.packing,
				is_complect: params?.is_complect,
				name: params?.name,
				order_id: params?.order_id || undefined,
				production: params?.production,
				order_name: params?.order_name
			}
		})
	}

	getById = ({ id }: { id?: number | null }) => {
		return $authHost.get<IRoutemapWithPaginationItem>('api/routemap/' + id)
	}

	getHistory = (params: IRouteMapHistoryParams) => {
		return $authHost.get<IRouteMapHistoryResponse>(`api/route_map_history/`, {
			params
		})
	}

	getOtkPassport = ({ id, preview }: { id: number; preview?: boolean }) => {
		return $authHost.post('api/get_otk_passport/' + id, { preview: preview })
	}

	updateIndicatorInstances = ({ id }: { id: number }) => {
		return $authHost.put('api/update_indicator_instances/' + id)
	}

	create = (routermap: IPostDtoRouteMap) => {
		return $authHost.post<IPostDtoRouteMap>('api/routemap/', routermap)
	}

	update = (routermap: IPutDtoRouteMap) => {
		return $authHost.put<IPutDtoRouteMap>(
			'api/routemap/' + routermap.id,
			routermap
		)
	}
	updateMany = (routermap: IPutDtoRouteMapList) => {
		return $authHost.put('api/routemap/', routermap)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IRouteMap>('api/routemap/' + id)
	}

	complateOperationsByLKK = ({ id }: { id: number }) => {
		return $authHost.post('api/complete_to_lqc/' + id)
	}

	completePrevLQC = ({ id }: { id: number }) => {
		return $authHost.post('api/complete_prev_lqc/' + id)
	}

	separation = (data: IPutDtoRouteMap) => {
		return $authHost.post<{ separated_route_map_id: number }>(
			'api/route_map_separation/' + data.id,
			data
		)
	}
}

export default new RoutemapService()
