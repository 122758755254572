import { $authHost } from '@/API'
import {
	IDtoPostRackView,
	IDtoPutRackView,
	IRackView,
	IRackViewParams,
} from '@/types/rackView.types'

class RackViewService {
	getAll = (params?: IRackViewParams) => {
		return $authHost.get<IRackView[]>('api/rack/', {
			params: {
				page: params?.pageParam || params?.page,
				name: params?.name,
				wh_id: params?.wh_id,
				zone_id: params?.zone_id
			}
		})
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<IRackView>('api/rack/' + id)
	}

	create = (data: IDtoPostRackView) => {
		return $authHost.post<IRackView>('api/rack/', data)
	}

	update = (data: IDtoPutRackView) => {
		return $authHost.put<IRackView>('api/rack/' + data.id, data)
	}

	updateMany = (data: IDtoPutRackView[]) => {
		return $authHost.put<IRackView>('api/rack/', data)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IRackView>('api/rack/' + id)
	}
}

export default new RackViewService()
