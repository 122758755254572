import { $authHost } from '@/API'
import {
	IDtoPostWarehouse,
	IDtoPutWarehouse,
	IWarehouse,
	IWarehouseParams,
	IWarehouseWithPagination
} from '@/types/warehouse.types'

class WarehouseService {
	getAll = (params?: IWarehouseParams) => {
		return $authHost.get<IWarehouseWithPagination>('api/warehouse/', {
			params: params
		})
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<IWarehouse>('api/warehouse/' + id)
	}

	create = (data: IDtoPostWarehouse) => {
		return $authHost.post<IWarehouse>('api/warehouse/', data)
	}

	update = (data: IDtoPutWarehouse) => {
		return $authHost.put<IWarehouse>('api/warehouse/' + data.id, data)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IWarehouse>('api/warehouse/' + id)
	}
}

export default new WarehouseService()
