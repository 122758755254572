import { Tag, TagProps } from 'antd'
import { FC } from 'react'

interface IProps {
	props?: TagProps
	is_production_?: boolean | null
	is_packing_?: boolean | null
	sb_test_part?: boolean | null
	add_packing?: boolean | null
}

const RoutemapTypeTag: FC<IProps> = routemap => {
	let color = 'blue'
	let text = 'InCanLine'

	if (routemap.is_production_) {
		text = 'Производственная'
		color = 'cyan'
	}
	if (routemap.is_packing_) {
		text = 'Фасовочная'
		color = 'orange'
	}
	if (routemap.sb_test_part) {
		text = 'Тестовая'
		color = 'green'
	}
	if (routemap.add_packing) {
		text = 'Дофасовка'
		color = 'red'
	}

	return (
		<Tag {...routemap?.props} color={color}>
			{text}
		</Tag>
	)
}

export default RoutemapTypeTag
