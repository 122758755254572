import { IDefualtObjectProps } from '../types/object.types'
import { ObjectStore } from './Object'
import { makeObservable } from 'mobx'

export class WHZoneStore extends ObjectStore {
	constructor(props: IDefualtObjectProps) {
		super({
			...props,
			type: 'zone',
			fill: props?.fill ? props?.fill : 'transparent',
			zIndex: 1
		})

		makeObservable(this, {})
	}
}
