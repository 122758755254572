import { $authHost } from '@/API'
import {
	IDtoPostWhzone,
	IDtoPutWhzone,
	IWhzone,
	IWhzoneParams
} from '@/types/whzone.types'

class WhzoneService {
	getAll = (params?: IWhzoneParams) => {
		return $authHost.get<IWhzone[]>('api/whzone/', {
			params: params
		})
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<IWhzone[]>('api/whzone/', {
			params: { id }
		})
	}

	create = (data: IDtoPostWhzone) => {
		return $authHost.post<IWhzone>('api/whzone/', data)
	}

	createMany = (data: IDtoPostWhzone[]) => {
		return $authHost.post<IWhzone>('api/whzone/', data)
	}

	update = (data: IDtoPutWhzone) => {
		return $authHost.put<IWhzone>('api/whzone/' + data.id, data)
	}

	updateMany = (data: IDtoPutWhzone[]) => {
		return $authHost.put<IWhzone>('api/whzone/', data)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IWhzone>('api/whzone/' + id)
	}
}

export default new WhzoneService()
