import { objectsStore } from './MapFullpageEditor'
import { MapStore } from './store/Map'
import { WHZoneStore } from './store/WHZone'
import Logotype from '@/components/Logotype'
import { usePutRackViewQuery } from '@/hooks/rackView.hook'
import useToastStatus from '@/hooks/useToastStatus.hook'
import { usePutWhzoneQuery } from '@/hooks/whzone.hook'
import { SaveOutlined } from '@ant-design/icons'
import { Button, Progress } from 'antd'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { BsZoomIn, BsZoomOut } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'

interface IProps {
	mapStore: MapStore
}

const HeaderPanel: FC<IProps> = ({ mapStore }) => {
	const updateRacks = usePutRackViewQuery()
	const updateWHZones = usePutWhzoneQuery()

	const [isProgressVisible, setIsProgressVisible] = useState(false)

	const handleZoomIn = () => {
		const zoomFactor = 1.1
		mapStore.setScale(mapStore.scale * zoomFactor)
	}

	const handleZoomOut = () => {
		const zoomFactor = 1.1
		mapStore.setScale(mapStore.scale / zoomFactor)
	}

	const handleSave = () => {
		const allSquarse = objectsStore.squares.map(square => {
			return {
				...square,
				type: square instanceof WHZoneStore ? 'zone' : 'rack'
			}
		})

		// Show Progress bar
		setIsProgressVisible(true)

		const whzones = allSquarse
			.filter(e => e.type === 'zone')
			.map(e => ({
				id: e.id,
				zonename: e.text.name,
				whzone_x: e.x / 50,
				whzone_y: e.y / 50,
				width: e.width / 50,
				length: e.height / 50,
				text_color: e.text.color,
				text_postition: e.text.placement,
				font_size: e.text.fontSize,
				color: e.fill
			}))

		const racks = allSquarse
			.filter(e => e.type === 'rack')
			.map(e => ({
				id: e.id,
				rackname: e.text.name,
				xcoord: e.x / 50,
				ycoord: e.y / 50,
				width: e.width / 50,
				height: e.height / 50,
				text_color: e.text.color,
				text_postition: e.text.placement,
				font_size: e.text.fontSize,
				color: e.fill
			}))

		// Hide Progress bar after successful requests and delay of 1.5 seconds
		Promise.all([
			updateRacks.mutateAsync(racks),
			updateWHZones.mutateAsync(whzones)
		]).then(() => {
			setTimeout(() => {
				setIsProgressVisible(false)
			}, 1500)
		})
	}

	const percent =
		(updateRacks.isPending ? 0 : 50) + (updateWHZones.isPending ? 0 : 50)

	return (
		<div className='fixed top-0 left-0 bg-white border-b border-gray-400 z-10 h-14 w-full px-5'>
			<div className='flex h-full items-center gap-3'>
				<NavLink to={'/'}>
					<Logotype className='max-w-[85px]' />
				</NavLink>
				<p>{Math.floor(mapStore.scale * 100)}%</p>
				<Button icon={<BsZoomIn />} onClick={handleZoomIn}></Button>
				<Button icon={<BsZoomOut />} onClick={handleZoomOut}></Button>
				<Button
					type='primary'
					loading={updateRacks.isPending || updateWHZones.isPending}
					icon={<SaveOutlined />}
					onClick={handleSave}
				>
					Save
				</Button>
				{isProgressVisible && (
					<div className='w-96'>
						<p className='text-center font-light text-xs -mb-2'>
							{percent !== 100 ? 'Обновление' : 'Успешно'}
						</p>
						<Progress
							// className='-mt-10'
							size={'small'}
							status={
								updateRacks.isError || updateWHZones.isError
									? 'exception'
									: undefined
							}
							percent={percent}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default observer(HeaderPanel)
