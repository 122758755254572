import Toolbar from './Toolbar'
import { MapStore } from './store/Map'
import { ObjectsListStore } from './store/ObjectsList'
import { FC } from 'react'

interface IProps {
	mapStore: MapStore
	squaresStore: ObjectsListStore
}

const LeftPanel: FC<IProps> = ({ mapStore, squaresStore }) => {
	return (
		<div className='fixed top-14 left-0 h-full bg-white w-1/6 max-w-[350px] p-2'>
			<Toolbar squaresStore={squaresStore} />
		</div>
	)
}

export default LeftPanel
