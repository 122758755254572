import { Card, Divider } from 'antd'
import { Typography } from 'antd'
import { FC, ReactNode } from 'react'

const { Title } = Typography

interface IProps {
	children?: ReactNode
	isCard?: boolean
}

const H6Heading: FC<IProps> = ({ children, isCard }) => {
	const title = (
		<Divider orientation='left'>
			<Title style={{ fontWeight: 300 }} className='mt-2' level={5}>
				{children}
			</Title>
		</Divider>
	)

	if (isCard) {
		return <Card className='mt-5'>{title}</Card>
	}

	return title
}

export default H6Heading
