import { $authHost } from '@/API'
import {
	ICompany,
	ICompanyHistoryParams,
	ICompanyHistoryResponse,
	ICompanyParams,
	IDtoPostCompany,
	IDtoPutCompany
} from '@/types/company.types'

class CompanyService {
	getAll = (params?: ICompanyParams) => {
		return $authHost.get<ICompany>('api/user_company/', { params })
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<ICompany>('api/user_company/' + id)
	}

	getHistory = (params: ICompanyHistoryParams) => {
		return $authHost.get<ICompanyHistoryResponse>(`api/company_history/`, {
			params
		})
	}

	create = (data: IDtoPostCompany) => {
		return $authHost.post<ICompany>('api/user_company/', data)
	}

	update = (data: IDtoPutCompany) => {
		if (data.logo) {
			return $authHost.putForm<ICompany>('api/user_company/', data)
		}
		return $authHost.put<ICompany>('api/user_company/', data)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<ICompany>('api/user_company/' + id)
	}
}

export default new CompanyService()
