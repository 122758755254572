import routeOperationService from '../services/routeOperation.service'
import {
	IDtoPostRouteOperation,
	IDtoPutRouteOperation,
	IRouteOperationHistoryParams,
	IRouteOperationParams
} from '../types/RouteOperation.types'
import {
	QUERY_ROUTE_OPERATION_ALL_KEY,
	QUERY_ROUTE_OPERATION_CREATE_KEY,
	QUERY_ROUTE_OPERATION_DELETE_KEY,
	QUERY_ROUTE_OPERATION_HISTORY_KEY,
	QUERY_ROUTE_OPERATION_KEY,
	QUERY_ROUTE_OPERATION_PUT_KEY
} from './../const/routeOperation.const'
import { QUERY_ROUTEMAP_KEY } from '@/const/routemap.const'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetRouteOperationAllQuery = (props?: IRouteOperationParams) =>
	useQuery({
		queryKey: [
			QUERY_ROUTE_OPERATION_ALL_KEY,
			props?.only_lqc,
			props?.route_map,
			props?.date,
			props?.work_station_id,
			props?.current_state_id
		],
		queryFn: async () =>
			await routeOperationService.getAll(props).then(res => res.data)
	})

export const useGetRouteOperationQuery = (id?: number | null) =>
	useQuery({
		queryKey: [QUERY_ROUTE_OPERATION_KEY, id],
		enabled: !!id && typeof id === 'number' && !isNaN(id),
		queryFn: async () =>
			await routeOperationService.getById({ id }).then(res => res.data)
	})

export const useGetRouteOperationHistoryQuery = (
	params: IRouteOperationHistoryParams
) =>
	useQuery({
		queryKey: [
			QUERY_ROUTE_OPERATION_HISTORY_KEY,
			params?.route_map_id,
			params?.page
		],
		enabled: !isNaN(Number(params?.route_map_id)),
		queryFn: async () =>
			await routeOperationService.getHistory(params).then(res => res.data)
	})

export const usePostRouteOperationQuery = (data: IDtoPostRouteOperation) =>
	useQuery({
		queryKey: [QUERY_ROUTE_OPERATION_CREATE_KEY],
		queryFn: async () =>
			await routeOperationService.create(data).then(res => res.data)
	})

export const usePutRouteOperationQuery = (params?: IRouteOperationParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTE_OPERATION_PUT_KEY],
		onSuccess: data => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTE_OPERATION_ALL_KEY]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_KEY, data?.routeMap_id]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTE_OPERATION_HISTORY_KEY]
			})
		},
		mutationFn: async (data: IDtoPutRouteOperation | IDtoPutRouteOperation[]) =>
			await routeOperationService.update(data).then(res => res.data)
	})
}

export const useDeleteRouteOperationQuery = (props?: IRouteOperationParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTE_OPERATION_DELETE_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTE_OPERATION_ALL_KEY, props?.route_map]
			})
		},
		mutationFn: async (id: number) =>
			await routeOperationService.delete({ id }).then(res => res.data)
	})
}
