import { IDefualtObjectProps } from '../types/object.types'
import { ObjectStore } from './Object'
import { makeObservable } from 'mobx'

export class RackStore extends ObjectStore {
	constructor(props: IDefualtObjectProps) {
		super({
			...props,
			type: 'rack',
			fill: props?.fill ? props?.fill : 'lightblue',
			zIndex: 2
		})
		makeObservable(this, {})
	}
}
