import { ObjectStore } from '../store/Object'
import H6Heading from '@/components/Layouts/Heading/H6Heading'
import { ColorPicker, Form, Input, InputNumber, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FC } from 'react'

interface IProps {
	squaresStore: ObjectStore
}

const RackSettings: FC<IProps> = ({ squaresStore }) => {
	// Локальное состояние для полей ввода, храним строковое представление
	const [xInput, setXInput] = useState(
		String(Math.floor((squaresStore.x / 50) * 100) / 100)
	)
	const [yInput, setYInput] = useState(
		String(Math.floor((squaresStore.y / 50) * 100) / 100)
	)
	const [widthInput, setWidthInput] = useState(
		String(Math.floor((squaresStore.width / 50) * 100) / 100)
	)
	const [heightInput, setHeightInput] = useState(
		String(Math.floor((squaresStore.height / 50) * 100) / 100)
	)

	// Если store обновится извне, синхронизируем локальное состояние
	useEffect(() => {
		setXInput(String(Math.floor((squaresStore.x / 50) * 100) / 100))
	}, [squaresStore.x])
	useEffect(() => {
		setYInput(String(Math.floor((squaresStore.y / 50) * 100) / 100))
	}, [squaresStore.y])
	useEffect(() => {
		setWidthInput(String(Math.floor((squaresStore.width / 50) * 100) / 100))
	}, [squaresStore.width])
	useEffect(() => {
		setHeightInput(String(Math.floor((squaresStore.height / 50) * 100) / 100))
	}, [squaresStore.height])

	// Функция обновления store, если значение корректное
	const handleChange = (
		value: string,
		onStoreChange: (n: number) => void,
		setInput: (s: string) => void
	) => {
		setInput(value)
		// Если введённое значение корректное число, обновляем store
		const num = parseFloat(value)
		if (!isNaN(num)) {
			onStoreChange(num * 50)
		}
	}

	// При уходе с поля ввода можно откатить некорректное значение
	const handleBlur = (
		input: string,
		current: number,
		setInput: (s: string) => void
	) => {
		if (input === '' || isNaN(parseFloat(input))) {
			setInput(String(Math.floor((current / 50) * 100) / 100))
		}
	}

	return (
		<Form className='font-light text-base' layout='vertical'>
			<Form.Item layout='horizontal' label='Цвет'>
				<ColorPicker
					size='small'
					value={squaresStore.fill}
					onChange={e => {
						squaresStore.fill = e?.toHexString()
					}}
				/>
			</Form.Item>

			<H6Heading>Настройка стеллажа</H6Heading>
			<div className='grid grid-cols-2 gap-3'>
				<Form.Item label='X позиция'>
					<Input
						size='small'
						value={xInput}
						suffix={'метров'}
						onChange={e =>
							handleChange(
								e.target.value,
								newVal => (squaresStore.x = newVal),
								setXInput
							)
						}
						onBlur={() => handleBlur(xInput, squaresStore.x, setXInput)}
					/>
				</Form.Item>
				<Form.Item label='Y позиция'>
					<Input
						size='small'
						value={yInput}
						suffix={'метров'}
						onChange={e =>
							handleChange(
								e.target.value,
								newVal => (squaresStore.y = newVal),
								setYInput
							)
						}
						onBlur={() => handleBlur(yInput, squaresStore.y, setYInput)}
					/>
				</Form.Item>
			</div>
			<div className='grid grid-cols-2 gap-3'>
				<Form.Item label='Ширина'>
					<Input
						size='small'
						value={widthInput}
						suffix={'метров'}
						onChange={e =>
							handleChange(
								e.target.value,
								newVal => (squaresStore.width = newVal),
								setWidthInput
							)
						}
						onBlur={() =>
							handleBlur(widthInput, squaresStore.width, setWidthInput)
						}
					/>
				</Form.Item>
				<Form.Item label='Высота'>
					<Input
						size='small'
						value={heightInput}
						suffix={'метров'}
						onChange={e =>
							handleChange(
								e.target.value,
								newVal => (squaresStore.height = newVal),
								setHeightInput
							)
						}
						onBlur={() =>
							handleBlur(heightInput, squaresStore.height, setHeightInput)
						}
					/>
				</Form.Item>
			</div>

			<H6Heading>Текст</H6Heading>
			<Form.Item label='Наименование'>
				<Input
					placeholder='Фигура'
					size='small'
					value={squaresStore.text.name}
					onChange={e => {
						squaresStore.text.name = e.target.value
					}}
				/>
			</Form.Item>
			<div className='flex'>
				<Form.Item layout='horizontal' label='Размер шрифта'>
					<InputNumber
						size='small'
						value={squaresStore.text.fontSize}
						suffix={'px'}
						onChange={e => {
							squaresStore.text.fontSize = e ? e : 16
						}}
					/>
				</Form.Item>
				<Form.Item layout='horizontal' label='Цвет'>
					<ColorPicker
						size='small'
						value={squaresStore.text.color}
						onChange={e => {
							squaresStore.text.color = e?.toHexString()
						}}
					/>
				</Form.Item>
			</div>
			<Form.Item layout='horizontal' label='Позиция'>
				<Select
					size='small'
					value={squaresStore.text.placement}
					onChange={value => {
						squaresStore.text.placement = value
					}}
				>
					{[
						'center',
						'top-right',
						'top-left',
						'top-center',
						'bottom-right',
						'bottom-left',
						'bottom-center',
						'middle-right',
						'middle-left'
					].map(placement => (
						<Select.Option key={placement} value={placement}>
							{placement}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
		</Form>
	)
}

export default observer(RackSettings)
