import RackSettings from './components/RackSettings'
import { MapStore } from './store/Map'
import { ObjectsListStore } from './store/ObjectsList'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
	mapStore: MapStore
	squaresStore: ObjectsListStore
}

const RightPanel: FC<IProps> = ({ mapStore, squaresStore }) => {
	if (squaresStore.selectedSquares.length !== 1) return <></>
	const selectedSquere = squaresStore.selectedSquares[0]
	return (
		<div className='fixed top-14 right-0 h-full bg-white w-full max-w-[300px] p-2'>
			<RackSettings squaresStore={selectedSquere} />
		</div>
	)
}

export default observer(RightPanel)
