import {
	QUERY_COMPANY_ALL_KEY,
	QUERY_COMPANY_DELETE_KEY,
	QUERY_COMPANY_HISTORY_KEY,
	QUERY_COMPANY_KEY,
	QUERY_COMPANY_POST_KEY,
	QUERY_COMPANY_PUT_KEY
} from '@/const/company.const'
import companyService from '@/services/company.service'
import {
	ICompanyHistoryParams,
	ICompanyParams,
	IDtoPostCompany,
	IDtoPutCompany
} from '@/types/company.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetCompanyAllQuery = (params?: ICompanyParams) =>
	useQuery({
		queryKey: [QUERY_COMPANY_ALL_KEY, params?.page],
		queryFn: async () =>
			await companyService.getAll(params).then(res => res.data)
	})

export const useGetCompanyQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_COMPANY_KEY, id],
		queryFn: async () =>
			await companyService.getById({ id }).then(res => res.data)
	})

export const useGetCompanyHistoryQuery = (params: ICompanyHistoryParams) =>
	useQuery({
		queryKey: [QUERY_COMPANY_HISTORY_KEY, params?.page],
		queryFn: async () =>
			await companyService.getHistory(params).then(res => res.data)
	})

export const usePostCompanyQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_COMPANY_POST_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_COMPANY_ALL_KEY]
			})
		},
		mutationFn: async (data: IDtoPostCompany) =>
			await companyService.create(data).then(res => res.data)
	})
}

export const usePutCompanyQuery = (params?: ICompanyParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_COMPANY_PUT_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_COMPANY_ALL_KEY]
			})
		},
		mutationFn: async (data: IDtoPutCompany) =>
			await companyService.update(data).then(res => res.data)
	})
}

export const useDeleteCompanyQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_COMPANY_DELETE_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_COMPANY_ALL_KEY]
			})
		},
		mutationFn: async (id: number) =>
			await companyService.delete({ id }).then(res => res.data)
	})
}
