import { useGetWhzoneAllQuery } from '@/hooks/whzone.hook'
import { IWhzoneParams } from '@/types/whzone.types'
import { Select, SelectProps } from 'antd'
import { FC } from 'react'

interface IWhzoneAsyncSelect extends SelectProps {
	params?: IWhzoneParams
}

const WhzoneAsyncSelect: FC<IWhzoneAsyncSelect> = ({ params, ...props }) => {
	const { data, isLoading, isSuccess } = useGetWhzoneAllQuery(params)

	return (
		<Select
			{...props}
			className={`w-full ${props.className}`}
			loading={isLoading}
		>
			{isSuccess &&
				data.map(whzone => (
					<Select.Option key={whzone.id} value={whzone.id}>
						{whzone.zonename}
					</Select.Option>
				))}
		</Select>
	)
}

export default WhzoneAsyncSelect
