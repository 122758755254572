import WarehouseAsyncSelect from '@/components/AsyncSelect/WarehouseAsyncSelect'
import useToastStatus from '@/hooks/useToastStatus.hook'
import { usePostWhzoneQuery } from '@/hooks/whzone.hook'
import { IDtoPostWhzone } from '@/types/whzone.types'
import { SaveOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Input, InputNumber, Switch, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC } from 'react'
import { IoAddOutline } from 'react-icons/io5'

interface IProps {
	open?: boolean
	onClose?: () => void
}

const WHZoneAdd: FC<IProps> = ({ onClose, open }) => {
	const [form] = Form.useForm<IDtoPostWhzone>()

	const { mutate, isPending, isSuccess, error, isError, status } =
		usePostWhzoneQuery()

	useToastStatus({ status })

	const onConfirm = async () => {
		try {
			const values = await form.validateFields()
			mutate(values)
			window.location.reload()
		} catch (error) {
			message.error('Ошибка! Заполните обязательные поля')
		}
	}

	return (
		<Drawer
			extra={
				<Button icon={<IoAddOutline />} type='primary' onClick={onConfirm}>
					Добавить
				</Button>
			}
			onClose={onClose}
			closable={false}
			title='Добавление складской зоны'
			placement='right'
			open={open}
		>
			<Form form={form} layout='vertical' className='font-light mt-3'>
				<Form.Item
					label='Наименование'
					name='zonename'
					rules={[
						{ required: true, message: 'Пожалуйста, введите наименование!' }
					]}
				>
					<Input size='large' placeholder='Введите наименование' />
				</Form.Item>
				<Form.Item label='Описание' name='zonedescr'>
					<TextArea rows={3} size='large' placeholder='Введите описание' />
				</Form.Item>
				<Form.Item label='Ширина' name='width'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите ширину'
					/>
				</Form.Item>
				<Form.Item label='Длина' name='length'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите длину'
					/>
				</Form.Item>

				<Form.Item label='Начальная координата X' name='startx'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите начальную координату X'
					/>
				</Form.Item>
				<Form.Item label='Начальная координата Y' name='starty'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите начальную координату Y'
					/>
				</Form.Item>
				<Form.Item label='Координата X' name='whzone_x'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите координату X'
					/>
				</Form.Item>
				<Form.Item label='Координата Y' name='whzone_y'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите координату Y'
					/>
				</Form.Item>
				<Form.Item label='Склад' name='wh'>
					<WarehouseAsyncSelect
						onChange={wh => {
							form.setFieldsValue({
								wh: wh.value
							})
						}}
						value={form.getFieldValue('wh')}
					/>
				</Form.Item>
				<Form.Item
					label='Показывать на карте'
					name='invisible'
					valuePropName='checked'
				>
					<Switch />
				</Form.Item>

				{isError && (
					<Form.Item>
						<p className='text-red-500 text-xs font-light'>{error?.message}</p>
					</Form.Item>
				)}
			</Form>
		</Drawer>
	)
}

export default WHZoneAdd
