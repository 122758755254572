// Map.tsx
import DraggableSquare from './DraggableSquare'
import HeaderPanel from './HeaderPanel'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import { MapStore } from './store/Map'
import { ObjectsListStore } from './store/ObjectsList'
import { RackStore } from './store/Rack'
import { WHZoneStore } from './store/WHZone'
import { TEXT_DEFUALT } from './types/text'
import { useGetRackViewAllQuery } from '@/hooks/rackView.hook'
import { useGetWhzoneAllQuery } from '@/hooks/whzone.hook'
import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

export const mapStore = new MapStore()
export const objectsStore = new ObjectsListStore()

const Map: React.FC = () => {
	// Инициализируем наши сторы

	const { id } = useParams()
	const racks = useGetRackViewAllQuery({ wh_id: id })
	const whzones = useGetWhzoneAllQuery({ wh_id: id })
	const svgRef = useRef<SVGSVGElement | null>(null)

	useEffect(() => {
		if (whzones.isSuccess && whzones.data) {
			whzones.data
				.filter(wh => wh.width && wh.length)
				.map(wh =>
					objectsStore.addSquare(
						new WHZoneStore({
							id: wh.id,
							y: wh.whzone_y * 50,
							x: wh.whzone_x * 50,
							width: (wh.width || 0) * 50,
							height: (wh.length || 0) * 50,
							fill: wh.color || undefined,
							text: {
								...TEXT_DEFUALT,
								name: wh.zonename,
								fontSize: wh.font_size || TEXT_DEFUALT.fontSize,
								color: wh.text_color || TEXT_DEFUALT.color,
								placement: wh.text_postition || TEXT_DEFUALT.placement
							}
						})
					)
				)
		}
	}, [whzones.isSuccess])

	useEffect(() => {
		if (racks.isSuccess && racks.data) {
			racks.data.map(wh =>
				objectsStore.addSquare(
					new RackStore({
						id: wh.id,
						y: (wh.ycoord || 0) * 50,
						x: (wh.xcoord || 0) * 50,
						width: (wh.width || 0) * 50,
						height: (wh.height || 0) * 50,
						fill: wh.color || undefined,
						text: {
							...TEXT_DEFUALT,
							name: wh.rackname,
							fontSize: wh.font_size || TEXT_DEFUALT.fontSize,
							color: wh.text_color || TEXT_DEFUALT.color,
							placement: wh.text_postition || TEXT_DEFUALT.placement
						}
					})
				)
			)
		}
	}, [racks.isSuccess])

	useEffect(() => {
		return () => {
			objectsStore.clear()
		}
	}, [])

	// Состояния для выделения рамкой
	const [selectionRect, setSelectionRect] = useState<{
		x: number
		y: number
		width: number
		height: number
	} | null>(null)

	const selectionStartRef = useRef<{ x: number; y: number } | null>(null)

	const [alignmentGuides, setAlignmentGuides] = useState<{
		vertical: number | null
		horizontal: number | null
	}>({ vertical: null, horizontal: null })

	// Состояния для панорамирования (перетаскивание средней кнопкой)
	const panActive = useRef(false)
	const panStartRef = useRef<{ x: number; y: number } | null>(null)

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			console.log('e.code', e.code)
			// Обработка Ctrl+C для копирования выделенных квадратов
			if (e.ctrlKey && e.code === 'KeyC') {
				e.preventDefault()
				objectsStore.copySelected()
			}
			// Обработка Ctrl+V для вставки ранее скопированных квадратов
			if (e.ctrlKey && e.code === 'KeyV') {
				e.preventDefault()
				objectsStore.pasteCopied()
			}
			// Для удаления квадратов
			if (e.code === 'Delete') {
				e.preventDefault()
				objectsStore.deleteSquere()
			}
			// Для удаления квадратов
			if (e.code === 'Escape') {
				e.preventDefault()
				objectsStore.deleteSeletions()
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	// onWheel – зум (без панорамирования)
	const handleWheel = (e: React.WheelEvent) => {
		e.preventDefault()
		if (!svgRef.current) return
		const rect = svgRef.current.getBoundingClientRect()
		const mouseX = e.clientX - rect.left
		const mouseY = e.clientY - rect.top
		const zoomFactor = 1.1
		let newScale = mapStore.scale
		if (e.deltaY < 0) {
			newScale *= zoomFactor
		} else {
			newScale /= zoomFactor
		}
		const dx = mouseX - mapStore.translateX
		const dy = mouseY - mapStore.translateY
		const newTranslateX = mouseX - (newScale / mapStore.scale) * dx
		const newTranslateY = mouseY - (newScale / mapStore.scale) * dy
		mapStore.setScale(newScale)
		mapStore.setTranslate(newTranslateX, newTranslateY)
	}

	// Обработчики для svg – как для выделения рамкой, так и для панорамирования
	const handleSvgMouseDown = (e: React.MouseEvent<SVGSVGElement>) => {
		// Если клик по фону (id="background")
		if ((e.target as SVGElement).id === 'background') {
			if (e.button === 0) {
				// Левая кнопка – начинаем выделение рамкой
				const svgRect = svgRef.current?.getBoundingClientRect()
				if (!svgRect) return
				const startX = e.clientX - svgRect.left
				const startY = e.clientY - svgRect.top
				selectionStartRef.current = { x: startX, y: startY }
				setSelectionRect({ x: startX, y: startY, width: 0, height: 0 })
			} else if (e.button === 1) {
				// Средняя кнопка – начинаем панорамирование
				panActive.current = true
				panStartRef.current = { x: e.clientX, y: e.clientY }
			}
		}
	}

	const handleSvgMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
		const svgRect = svgRef.current?.getBoundingClientRect()
		if (!svgRect) return

		if (panActive.current && panStartRef.current) {
			// Панорамирование: вычисляем смещение
			const dx = e.clientX - panStartRef.current.x
			const dy = e.clientY - panStartRef.current.y
			mapStore.updateTranslate(dx, dy)
			panStartRef.current = { x: e.clientX, y: e.clientY }
		} else if (selectionStartRef.current) {
			// Обновляем рамку выделения
			const currentX = e.clientX - svgRect.left
			const currentY = e.clientY - svgRect.top
			const startX = selectionStartRef.current.x
			const startY = selectionStartRef.current.y
			const rectX = Math.min(startX, currentX)
			const rectY = Math.min(startY, currentY)
			const rectWidth = Math.abs(currentX - startX)
			const rectHeight = Math.abs(currentY - startY)
			setSelectionRect({
				x: rectX,
				y: rectY,
				width: rectWidth,
				height: rectHeight
			})
		}
	}

	const handleSvgMouseUp = (e: React.MouseEvent<SVGSVGElement>) => {
		if (panActive.current) {
			panActive.current = false
			panStartRef.current = null
		}
		if (selectionStartRef.current && selectionRect) {
			// При завершении выделения переведём координаты рамки в координаты холста
			const rectCanvas = {
				x: (selectionRect.x - mapStore.translateX) / mapStore.scale,
				y: (selectionRect.y - mapStore.translateY) / mapStore.scale,
				width: selectionRect.width / mapStore.scale,
				height: selectionRect.height / mapStore.scale
			}
			// Выбираем квадрат, если он полностью попадает в рамку
			objectsStore.squares.forEach(sq => {
				if (
					sq.x >= rectCanvas.x &&
					sq.y >= rectCanvas.y &&
					sq.x + sq.width <= rectCanvas.x + rectCanvas.width &&
					sq.y + sq.height <= rectCanvas.y + rectCanvas.height
				) {
					sq.setSelected(true)
				}
			})
		}
		selectionStartRef.current = null
		setSelectionRect(null)
	}

	if (whzones.isPending || racks.isPending) {
		return (
			<div className='h-screen w-screen flex justify-center items-center'>
				<div className='flex flex-col items-center font-light'>
					<Spin size='large' />
					<p>Получаем данные для отрисовки карты</p>
				</div>
			</div>
		)
	}

	return (
		<>
			<HeaderPanel mapStore={mapStore} />
			<LeftPanel mapStore={mapStore} squaresStore={objectsStore} />
			<RightPanel mapStore={mapStore} squaresStore={objectsStore} />
			<svg
				ref={svgRef}
				width='100vw'
				height='100vh'
				onWheel={handleWheel}
				onMouseDown={handleSvgMouseDown}
				onMouseMove={handleSvgMouseMove}
				onMouseUp={handleSvgMouseUp}
				onMouseLeave={handleSvgMouseUp}
				style={{ background: '#f9f8ef', cursor: 'default' }}
			>
				<defs>
					<pattern
						id='grid'
						width='50'
						height='50'
						patternUnits='userSpaceOnUse'
					>
						<path
							d='M 50 0 L 0 0 0 50'
							fill='none'
							stroke='black'
							stroke-width='0.5'
						/>
					</pattern>
				</defs>
				{/* Глобальная трансформация карты */}
				<g
					transform={`translate(${mapStore.translateX}, ${mapStore.translateY}) scale(${mapStore.scale})`}
				>
					<rect
						id='background'
						x={(mapStore.width / 2) * -1}
						y={(mapStore.height / 2) * -1}
						width={mapStore.width}
						height={mapStore.height}
						fill='url(#grid)'
					/>
					{alignmentGuides?.vertical && (
						<line
							x1={alignmentGuides.vertical}
							y1={-mapStore.height / 2}
							x2={alignmentGuides.vertical}
							y2={mapStore.height / 2}
							stroke='red'
							strokeDasharray='5'
							strokeWidth={1}
						/>
					)}
					{alignmentGuides?.horizontal && (
						<line
							x1={-mapStore.width / 2}
							y1={alignmentGuides.horizontal}
							x2={mapStore.width / 2}
							y2={alignmentGuides.horizontal}
							stroke='red'
							strokeDasharray='5'
							strokeWidth={1}
						/>
					)}
					{objectsStore.squares.map(sq => (
						<DraggableSquare
							key={`${sq.id}`}
							square={sq}
							squaresStore={objectsStore}
							mapStore={mapStore}
							// @ts-ignore
							onSnapping={setAlignmentGuides}
						/>
					))}
				</g>
				{/* Рамка выделения (отображается поверх, в координатах svg) */}
				{selectionRect && (
					<>
						<rect
							x={selectionRect.x}
							y={selectionRect.y}
							width={selectionRect.width}
							height={selectionRect.height}
							fill='rgba(0, 120, 215, 0.2)'
							stroke='rgba(0, 120, 215, 0.8)'
							strokeDasharray='4'
						/>
						<text
							x={selectionRect.x}
							y={selectionRect.y + selectionRect.height + 15} // 15px ниже рамки
							fill='black'
							fontSize='12'
						>
							{(() => {
								// Размеры с учетом зума
								const effectiveWidth =
									(selectionRect.width / mapStore.scale / 50) * 100
								const effectiveHeight =
									(selectionRect.height / mapStore.scale / 50) * 100
								const ratio =
									effectiveHeight !== 0
										? (effectiveWidth / effectiveHeight).toFixed(2)
										: '0'
								return `${Math.round(effectiveWidth) / 100}м x ${
									Math.round(effectiveHeight) / 100
								}м, Ratio: ${ratio}`
							})()}
						</text>
					</>
				)}
			</svg>
		</>
	)
}

export default observer(Map)
