import {
	QUERY_RACK_VIEW_ALL_KEY,
	QUERY_RACK_VIEW_CREATE_KEY,
	QUERY_RACK_VIEW_DELETE_KEY,
	QUERY_RACK_VIEW_KEY,
	QUERY_RACK_VIEW_PUT_KEY
} from '@/const/rackView.const'
import rackViewService from '@/services/rackView.service'
import {
	IDtoPostRackView,
	IDtoPutRackView,
	IRackView,
	IRackViewParams,
	IRackViewWithPagination
} from '@/types/rackView.types'
import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient
} from '@tanstack/react-query'

export const useGetRackViewAllQuery = (params?: IRackViewParams) =>
	useQuery({
		queryKey: [QUERY_RACK_VIEW_ALL_KEY, params?.wh_id],
		queryFn: async () =>
			await rackViewService.getAll(params).then(res => res.data)
	})

export const useGetInfinityRackViewAllQuery = (params?: IRackViewParams) =>
	useInfiniteQuery({
		queryKey: [QUERY_RACK_VIEW_ALL_KEY],
		initialPageParam: 1,
		select(data) {
			// @ts-ignore
			return data.pages.map(e => e).flat() as IRackView[]
		},
		getNextPageParam(lastPage, allPages) {
			const page = allPages.length
			// @ts-ignore
			return page * lastPage.page_count < lastPage.total
				? allPages.length + 1
				: undefined
		},
		queryFn: async paramsPage =>
			await rackViewService
				.getAll({ ...params, ...paramsPage })
				.then(res => res.data)
	})

export const useGetRackViewQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_RACK_VIEW_KEY, id],
		queryFn: async () =>
			await rackViewService.getById({ id }).then(res => res.data)
	})

export const usePostRackViewQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_RACK_VIEW_CREATE_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_RACK_VIEW_ALL_KEY] })
		},
		mutationFn: async (data: IDtoPostRackView) =>
			await rackViewService.create(data).then(res => res.data)
	})
}

export const usePutRackViewQuery = (params?: IRackViewParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_RACK_VIEW_PUT_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_RACK_VIEW_ALL_KEY] })
		},
		mutationFn: async (data: IDtoPutRackView | IDtoPutRackView[]) => {
			if (Array.isArray(data)) {
				return await rackViewService.updateMany(data).then(res => res.data)
			}
			return await rackViewService.update(data).then(res => res.data)
		}
	})
}

export const useDeleteRackViewQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_RACK_VIEW_DELETE_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_RACK_VIEW_ALL_KEY]
			})
		},
		mutationFn: async (id: number) =>
			await rackViewService.delete({ id }).then(res => res.data)
	})
}
