import { $authHost } from '@/API'
import {
	IDtoPostByIdReport,
	IDtoPostReport,
	IGenerateRouteMapsListReportParams,
	IGetReport,
	IGetReportWithPagination,
	IReportParams
} from '@/types/getReport.types'

class GetReportService {
	getAll = (params: IReportParams) => {
		return $authHost.get<IGetReportWithPagination>('api/check_report/', {
			params
		})
	}

	delete = (id: number) => {
		return $authHost.delete<IGetReport[]>('api/deactivate_reports/' + id)
	}

	printById = ({ id, data }: { id: number; data: IDtoPostByIdReport }) => {
		return $authHost.post('api/get_report/' + id, data)
	}

	printAll = (data: IDtoPostReport) => {
		return $authHost.post('api/get_report/', data)
	}

	downloadAll = (data: IDtoPostReport) => {
		return $authHost.post('api/get_report/' + data.route_maps[0], {
			report_type_id: data.report_type_id
		})
	}

	generateReport = (params: IGenerateRouteMapsListReportParams) => {
		return $authHost.post<IGetReport>(
			'api/generate_route_maps_list_report/',
			params
		)
	}

	getById = (id?: number) => {
		return $authHost.get<IGetReport>('api/check_report/' + id)
	}
}

export default new GetReportService()
