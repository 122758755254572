import {
	QUERY_WHZONE_ALL_KEY,
	QUERY_WHZONE_CREATE_KEY,
	QUERY_WHZONE_DELETE_KEY,
	QUERY_WHZONE_KEY,
	QUERY_WHZONE_PUT_KEY
} from '@/const/whzone.const'
import whzoneService from '@/services/whzone.service'
import {
	IDtoPostWhzone,
	IDtoPutWhzone,
	IWhzone,
	IWhzoneParams
} from '@/types/whzone.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetWhzoneAllQuery = (params?: IWhzoneParams) => {
	return useQuery({
		queryKey: [QUERY_WHZONE_ALL_KEY],
		queryFn: async () =>
			await whzoneService.getAll(params).then(res => res.data)
	})
}

export const useGetWhzoneQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_WHZONE_KEY, id],
		select: data => {
			return data[0]
		},
		queryFn: async () =>
			await whzoneService.getById({ id }).then(res => res.data)
	})

export const usePostWhzoneQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_WHZONE_CREATE_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_WHZONE_ALL_KEY] })
		},
		mutationFn: async (data: IDtoPostWhzone | IDtoPostWhzone[]) => {
			if (Array.isArray(data)) {
				return await whzoneService.createMany(data).then(res => res.data)
			}
			return await whzoneService.create(data).then(res => res.data)
		}
	})
}

export const usePutWhzoneQuery = (params?: IWhzoneParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_WHZONE_PUT_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_WHZONE_KEY] })
		},
		mutationFn: async (data: IDtoPutWhzone | IDtoPutWhzone[]) => {
			if (Array.isArray(data)) {
				return await whzoneService.updateMany(data).then(res => res.data)
			}
			return await whzoneService.update(data).then(res => res.data)
		}
	})
}

export const useDeleteWhzoneQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_WHZONE_DELETE_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_WHZONE_ALL_KEY] })
		},
		mutationFn: async (id: number) =>
			await whzoneService.delete({ id }).then(res => res.data)
	})
}
