import ManualLoader from './pages/ManualLoader/ManualLoader'
import ManualLoaderOpeartions from './pages/ManualLoader/ManualLoaderOpeartions'
import MapFullpageEditor from './pages/MapFullpageEditor/MapFullpageEditor'
import { IRoutes } from './types/routes'
import {
	ADMINISTRATOR_ROUTE,
	ITEGRATION1C_ROUTE,
	LOGIN_ROUTE,
	MAIN_ROUTE,
	TESTING_ROUTE
} from './utils/consts'
import { lazy } from 'react'

const LoginC = lazy(() => import('./pages/Login/LoginC'))
const MainPage = lazy(() => import('./pages/MainPage/MainPage'))
const TYCollectPage = lazy(() => import('./pages/TYCollect/TYCollect'))

const WarehouseMapPage = lazy(
	() => import('./pages/WarehouseMapPage/WarehouseMapPage')
)

const CollectMapList = lazy(
	() => import('./pages/CollectMapList/CollectMapList')
)
const HandlerOperation = lazy(
	() => import('./pages/MapCollector/HandlerOperation')
)
const DatabaseTable = lazy(() => import('./pages/DatabaseTable/DatabaseTable'))
const Integration1C = lazy(() => import('./pages/Integration1C/Integration1C'))
const TestingPage = lazy(() => import('./pages/TestingPage/TestingPage'))
const AdminPage = lazy(() => import('./pages/AdminPage/AdminPage'))
const GantSkelet = lazy(() => import('./components/GantStatic/GantSkelet'))

//Пути которые доступны всем пользователям
export const publicRoutes: IRoutes[] = [
	{
		path: LOGIN_ROUTE,
		Сomponent: LoginC
	},
	{
		path: TESTING_ROUTE,
		Сomponent: TestingPage
	},
	{
		path: '/map/:id',
		Сomponent: MapFullpageEditor
	},

	{
		path: MAIN_ROUTE,
		Сomponent: MainPage
	},

	{
		path: ITEGRATION1C_ROUTE,
		Сomponent: Integration1C
	},
	{
		path: ADMINISTRATOR_ROUTE,
		Сomponent: AdminPage
	},
	{
		path: '/collect/:id',
		Сomponent: TYCollectPage
	},
	{
		path: '/business-processes/*',
		Сomponent: GantSkelet
	},
	{
		path: '/warehouse-map/:id',
		Сomponent: WarehouseMapPage
	},
	{
		path: '/database-table',
		Сomponent: DatabaseTable
	},
	{
		path: '/map-collector/:id',
		Сomponent: HandlerOperation
	},
	{
		path: '/map-list',
		Сomponent: CollectMapList
	},
	{
		path: '/manual-loader',
		Сomponent: ManualLoader
	},
	{
		path: '/manual-loader/:route_map_id',
		Сomponent: ManualLoaderOpeartions
	}
]

//Пути которые доступны только сборщикам
export const collectManRoutes: IRoutes[] = []

//Пути которые доступны только кладовщикам
export const pullManRoutes: IRoutes[] = [
	{
		path: LOGIN_ROUTE,
		Сomponent: LoginC
	}
]

//Пути которые доступны только кладовщикам
export const fastomatRoutes: IRoutes[] = []
