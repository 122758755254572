// Toolbar.tsx
import RackAdd from './components/RackAdd'
import WHZoneAdd from './components/WHZoneAdd'
import { ObjectsListStore } from './store/ObjectsList'
import { Button, Drawer } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { MdOutlineCropSquare } from 'react-icons/md'
import { PiLockers } from 'react-icons/pi'

interface ToolbarProps {
	squaresStore: ObjectsListStore
}

const Toolbar: FC<ToolbarProps> = ({ squaresStore }) => {
	const [open, setOpen] = useState(false)
	const [openWhZone, setOpenWhZone] = useState(false)

	const handleAddSquare = () => {
		setOpen(true)
		// squaresStore.addSquare(new Rack)
	}

	const handleAddZone = () => {
		setOpenWhZone(true)
		console.log(toJS(squaresStore.squares))
		// squaresStore.addSquare({ type: 'zone' })
	}

	const onClose = () => {
		setOpen(false)
	}

	return (
		<div className='flex flex-col gap-3'>
			<Button icon={<PiLockers />} onClick={handleAddSquare}>
				Добавить стеллаж
			</Button>
			<Button icon={<MdOutlineCropSquare />} onClick={handleAddZone}>
				Добавить зону
			</Button>
			<RackAdd open={open} onClose={onClose} />
			<WHZoneAdd open={openWhZone} onClose={() => setOpenWhZone(false)} />
		</div>
	)
}

export default observer(Toolbar)
