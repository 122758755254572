import warehouseService from '@/services/warehouse.service'
import { IAsyncSelectValues } from '@/types/Specifications.types'
import { message } from 'antd'
import axios from 'axios'
import { FC, useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'

interface IProps {
	value: IAsyncSelectValues | null
	onChange: (value: IAsyncSelectValues) => void
}

const WarehouseAsyncSelect: FC<IProps> = ({ value, onChange }) => {
	const [page, setPage] = useState(1)
	const [prevSearch, setSearch] = useState('')
	const loadOptions = async (search: string) => {
		try {
			const currentPage = search !== prevSearch ? 1 : page
			if (search !== prevSearch) {
				setPage(1)
			} else {
				setPage(prev => prev + 1)
			}
			setSearch(search)
			const { data } = await warehouseService.getAll({
				name: search || undefined,
				page: currentPage
			})

			return {
				options: data.data.map(workcenter => ({
					value: workcenter.id,
					label: workcenter.whname
				})),
				hasMore: data.total / data.page_count > page
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				message.error(`Ошибка! ${error.message}`)
			}
			return {
				options: [],
				hasMore: true
			}
		}
	}

	return (
		<AsyncPaginate
			defaultValue={value}
			noOptionsMessage={() => 'Не найдено'}
			placeholder='Не указан'
			loadingMessage={() => 'Загрузка'}
			debounceTimeout={500}
			loadOptions={query => loadOptions(query)}
			onChange={newFilter => newFilter && onChange(newFilter)}
			className='w-full flex-1 shrink-0 h-10'
		/>
	)
}

export default WarehouseAsyncSelect
