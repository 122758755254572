import { IObjectsListType } from '../types/types'
import { RackStore } from './Rack'
import { WHZoneStore } from './WHZone'
import { makeAutoObservable } from 'mobx'

export class ObjectsListStore {
	squares: IObjectsListType[] = []
	nextId: number = 1
	clipboard: IObjectsListType[] = []

	constructor() {
		makeAutoObservable(this)
	}

	clear = () => {
		this.squares = []
		this.clipboard = []
	}

	addSquare(squareData: IObjectsListType) {
		this.squares.push(squareData)
	}

	removeSquare(id: number) {
		this.squares = this.squares.filter(sq => sq.id !== id)
	}

	clearSelection() {
		this.squares.forEach(sq => sq.setSelected(false))
	}

	selectSquare(id: number, multi: boolean = false) {
		if (!multi) {
			this.clearSelection()
		}
		const square = this.squares.find(sq => sq.id === id)
		if (square) {
			square.setSelected(true)
		}
	}

	toggleSquareSelection(id: number) {
		const square = this.squares.find(sq => sq.id === id)
		if (square) {
			square.setSelected(!square.selected)
		}
	}

	checkSnapping(
		movingSquare: IObjectsListType,
		squares: IObjectsListType[],
		threshold: number = 3
	): {
		snapDx: number
		snapDy: number
		verticalGuide: number | null
		horizontalGuide: number | null
	} {
		let snapDx = 0
		let snapDy = 0
		let verticalGuide: number | null = null
		let horizontalGuide: number | null = null

		const movingCenterX = movingSquare.x + movingSquare.width / 2
		const movingCenterY = movingSquare.y + movingSquare.height / 2

		squares.forEach(other => {
			if (other.id === movingSquare.id) return // пропускаем себя
			const otherCenterX = other.x + other.width / 2
			const otherCenterY = other.y + other.height / 2

			if (Math.abs(movingCenterX - otherCenterX) < threshold) {
				snapDx = otherCenterX - movingCenterX
				verticalGuide = otherCenterX
			}
			if (Math.abs(movingCenterY - otherCenterY) < threshold) {
				snapDy = otherCenterY - movingCenterY
				horizontalGuide = otherCenterY
			}
		})

		return { snapDx, snapDy, verticalGuide, horizontalGuide }
	}

	deleteSquere = () => {
		this.squares = this.squares.filter(sq => !sq.selected)
	}

	deleteSeletions = () => {
		this.squares = this.squares.map(sq => {
			sq.selected = false
			return sq
		})
	}

	get selectedSquares() {
		return this.squares.filter(sq => sq.selected)
	}

	// Поднимает квадрат на передний план: обновляет zIndex так, чтобы он стал максимальным
	bringToFront(id: number) {
		const square = this.squares.find(sq => sq.id === id)
		if (square instanceof WHZoneStore) {
			if (square) {
				square.setZIndex(1)
			}
		} else {
			const maxZ = Math.max(...this.squares.map(sq => sq.zIndex), 0)
			if (square) {
				square.setZIndex(maxZ + 1)
			}
		}

		// Сортировка квадратов по zIndex для корректного рендера
		this.squares.sort((a, b) => a.zIndex - b.zIndex)
	}

	/*************  ✨ Codeium Command ⭐  *************/
	/**
	 * Copies the currently selected squares to the clipboard.
	 */
	/******  94a9c112-88e4-469b-9f50-673f89aac99e  *******/
	copySelected() {
		this.clipboard = this.selectedSquares
	}

	pasteCopied() {
		this.clearSelection()
		this.clipboard.forEach(data => {
			if (data instanceof RackStore) {
				this.addSquare(
					new RackStore({
						...data,
						x: data.x + 20,
						y: data.y + 20,
						selected: true
					})
				)
			}
			if (data instanceof WHZoneStore) {
				this.addSquare(
					new WHZoneStore({
						...data,
						x: data.x + 20,
						y: data.y + 20,
						selected: true
					})
				)
			}
		})
	}
}
