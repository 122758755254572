import user from '../store/user'
import axios, { InternalAxiosRequestConfig } from 'axios'

export const errorHandler = async (e: any, func: any) => {
	console.error('Error in request')
	if (window.location.pathname === '/login') return
	if ((e.response.status || 0) === 401) {
		const refresh = localStorage.getItem('refresh_token')
		try {
			const { data } = await $host.post('api/login/refresh/', { refresh })
			localStorage.setItem('access_token', data.access)
			func()
		} catch (e) {
			user.Authorization = false
		}
	}
}

// Создаем два запроса
// один обычный, второй с токеном
const $host = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: false
})

// Создаем два запроса
// один обычный, второй с токеном
const $authHost = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: false
})

// Создаем функцию добавляющую конфигу токен
const authInterceptor = (config: InternalAxiosRequestConfig) => {
	if (!config || !config.headers) {
		throw Error('Axios: не задан config')
	}
	const token = localStorage.getItem('access_token')
	config.headers.Authorization = `Bearer ${token}`
	return config
}

// Навешиваем функцию с изменением конфига
// на "отлавливатель" запросов
$authHost.interceptors.request.use(authInterceptor)

$authHost.interceptors.response.use(
	response => response,
	error => {
		// Reject promise if usual error
		if (error.response.status !== 401) {
			return Promise.reject(error)
		}

		/*
		 * When response code is 401, try to refresh the token.
		 * Eject the interceptor so it doesn't loop in case
		 * token refresh causes the 401 response.
		 *
		 * Must be re-attached later on or the token refresh will only happen once
		 */
		const refresh = localStorage.getItem('refresh_token')
		return $host
			.post('api/login/refresh/', { refresh })
			.then(response => {
				localStorage.setItem('access_token', response.data.access)

				error.response.config.headers['Authorization'] =
					'Bearer ' + response.data.access
				// Retry the initial call, but with the updated token in the headers.
				// Resolves the promise if successful
				return axios(error.response.config)
			})
			.catch(error2 => {
				user.Authorization = false
				localStorage.removeItem('access_token')
				localStorage.removeItem('refresh_token')
				// Retry failed, clean up and reject the promise
				return Promise.reject(error2)
			})
	}
)

$authHost.interceptors.response.use(
	response => response,
	error => {
		const modifiedError = error
		let errorMessage = 'Произошла неизвестная ошибка'
		if (error.response?.data?.details) {
			errorMessage = error.response.data.details
		}
		if (error.response?.data?.detail) {
			errorMessage = error.response.data.detail
		}
		modifiedError.message = errorMessage
		throw modifiedError
	}
)
$host.interceptors.response.use(
	response => response,
	error => {
		const modifiedError = error
		let errorMessage = 'Произошла неизвестная ошибка'
		if (error.response?.data?.details) {
			errorMessage = error.response.data.details
		}
		if (error.response?.data?.detail) {
			errorMessage = error.response.data.detail
		}
		modifiedError.message = errorMessage
		throw modifiedError
	}
)

export { $host, $authHost }
