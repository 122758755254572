// DraggableSquare.tsx
import { MapStore } from './store/Map'
import { ObjectStore } from './store/Object'
import { ObjectsListStore } from './store/ObjectsList'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'

type DragOperation =
	| 'move'
	| 'resize-tl'
	| 'resize-tr'
	| 'resize-bl'
	| 'resize-br'
	| null

interface DraggableSquareProps {
	square: ObjectStore
	squaresStore: ObjectsListStore
	mapStore: MapStore
	// Колбэк для обновления направляющих линий (null – сброс)
	onSnapping: (
		guides: { vertical?: number; horizontal?: number } | null
	) => void
}

const DraggableSquare: React.FC<DraggableSquareProps> = ({
	square,
	squaresStore,
	mapStore,
	onSnapping
}) => {
	const dragOperation = useRef<DragOperation>(null)
	const lastPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

	const onMouseMove = (e: MouseEvent) => {
		if (!dragOperation.current) return

		// Учитываем зум: преобразуем смещение из экранных координат в координаты холста
		const dx = (e.clientX - lastPos.current.x) / mapStore.scale
		const dy = (e.clientY - lastPos.current.y) / mapStore.scale

		if (dragOperation.current === 'move') {
			if (squaresStore.selectedSquares.length > 1 && square.selected) {
				squaresStore.selectedSquares.forEach(sq => {
					sq.updatePosition(dx, dy)
				})
			} else {
				square.updatePosition(dx, dy)
				// Проверка выравнивания
				const { snapDx, snapDy, verticalGuide, horizontalGuide } =
					squaresStore.checkSnapping(square, squaresStore.squares, 5)
				if (snapDx !== 0 || snapDy !== 0) {
					square.updatePosition(snapDx, snapDy)
					onSnapping({
						vertical: verticalGuide || undefined,
						horizontal: horizontalGuide || undefined
					})
				} else {
					onSnapping(null)
				}
			}
		} else {
			// Изменение размеров
			switch (dragOperation.current) {
				case 'resize-tl':
					square.setPosition(square.x + dx, square.y + dy)
					square.setSize(
						Math.max(square.width - dx, 10),
						Math.max(square.height - dy, 10)
					)
					break
				case 'resize-tr':
					square.setPosition(square.x, square.y + dy)
					square.setSize(
						Math.max(square.width + dx, 10),
						Math.max(square.height - dy, 10)
					)
					break
				case 'resize-bl':
					square.setPosition(square.x + dx, square.y)
					square.setSize(
						Math.max(square.width - dx, 10),
						Math.max(square.height + dy, 10)
					)
					break
				case 'resize-br':
					square.setSize(
						Math.max(square.width + dx, 10),
						Math.max(square.height + dy, 10)
					)
					break
			}
			onSnapping(null)
		}
		lastPos.current = { x: e.clientX, y: e.clientY }
	}

	const onMouseUp = () => {
		dragOperation.current = null
		onSnapping(null)
		window.removeEventListener('mousemove', onMouseMove)
		window.removeEventListener('mouseup', onMouseUp)
	}

	const handleMouseDown = (
		e: React.MouseEvent,
		operation: DragOperation = 'move'
	) => {
		e.stopPropagation()
		if (!e.shiftKey && !e.ctrlKey) {
			squaresStore.clearSelection()
			squaresStore.selectSquare(square.id, true)
		} else {
			squaresStore.selectSquare(square.id, true)
		}
		squaresStore.bringToFront(square.id)
		dragOperation.current = operation
		lastPos.current = { x: e.clientX, y: e.clientY }
		window.addEventListener('mousemove', onMouseMove)
		window.addEventListener('mouseup', onMouseUp)
	}

	// Функция для расчета позиции текста в зависимости от `placement`
	const getTextPosition = () => {
		const { placement, name } = square.text
		const textPadding = 5

		let x = square.width / 2
		let y = square.height / 2

		switch (placement) {
			case 'top-left':
				x = textPadding
				y = 0
				break
			case 'top-center':
				x = square.width / 2
				y = 0
				break
			case 'top-right':
				x = square.width - textPadding
				y = 0
				break
			case 'middle-left':
				x = textPadding
				y = square.height / 2
				break
			case 'center':
				x = square.width / 2
				y = square.height / 2
				break
			case 'middle-right':
				x = square.width - textPadding
				y = square.height / 2
				break
			case 'bottom-left':
				x = textPadding
				y = square.height - textPadding
				break
			case 'bottom-center':
				x = square.width / 2
				y = square.height - textPadding
				break
			case 'bottom-right':
				x = square.width - textPadding
				y = square.height - textPadding
				break
		}

		return { x, y }
	}

	const { x: textX, y: textY } = getTextPosition()

	function getDominantBaseline(placement: string) {
		if (placement.startsWith('top')) return 'hanging'
		if (placement.startsWith('bottom')) return 'baseline'
		return 'middle'
	}

	function getTextAnchor(placement: string) {
		if (placement.includes('left')) return 'start'
		if (placement.includes('right')) return 'end'
		return 'middle'
	}

	const handleSize = 8

	return (
		<g transform={`translate(${square.x}, ${square.y})`}>
			{/* Основной прямоугольник */}
			<rect
				x={0}
				y={0}
				width={square.width}
				height={square.height}
				fill={square.fill}
				stroke={square.selected ? 'orange' : 'black'}
				strokeWidth={square.selected ? 3 : 1}
				onMouseDown={e => handleMouseDown(e, 'move')}
				style={{ cursor: 'move' }}
			/>
			{square.selected && (
				<>
					{/* Ручки для изменения размеров */}
					<rect
						x={-handleSize / 2}
						y={-handleSize / 2}
						width={handleSize}
						height={handleSize}
						fill='darkblue'
						onMouseDown={e => handleMouseDown(e, 'resize-tl')}
						style={{ cursor: 'nwse-resize' }}
					/>
					<rect
						x={square.width - handleSize / 2}
						y={-handleSize / 2}
						width={handleSize}
						height={handleSize}
						fill='darkblue'
						onMouseDown={e => handleMouseDown(e, 'resize-tr')}
						style={{ cursor: 'nesw-resize' }}
					/>
					<rect
						x={-handleSize / 2}
						y={square.height - handleSize / 2}
						width={handleSize}
						height={handleSize}
						fill='darkblue'
						onMouseDown={e => handleMouseDown(e, 'resize-bl')}
						style={{ cursor: 'nesw-resize' }}
					/>
					<rect
						x={square.width - handleSize / 2}
						y={square.height - handleSize / 2}
						width={handleSize}
						height={handleSize}
						fill='darkblue'
						onMouseDown={e => handleMouseDown(e, 'resize-br')}
						style={{ cursor: 'nwse-resize' }}
					/>
				</>
			)}

			<text
				x={textX}
				y={textY}
				textAnchor={getTextAnchor(square.text.placement)}
				dominantBaseline={getDominantBaseline(square.text.placement)}
				fill={square.text.color}
				fontSize={square.text.fontSize}
				style={{ pointerEvents: 'none' }}
			>
				{square.text.name}
			</text>
		</g>
	)
}

export default observer(DraggableSquare)
