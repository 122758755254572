import { FC, ReactNode } from 'react'

interface IProps {
	children?: ReactNode
}

const ContentContainer: FC<IProps> = ({ children }) => {
	return <div className='mx-auto font-semibold px-5 max-w-[3440px] min-h-[calc(100vh-64px)]'>{children}</div>
}

export default ContentContainer
