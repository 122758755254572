
export const TEXT_DEFUALT: ISqureText = {
	name: '',
	color: 'black',
	placement: 'center',
	fontSize: 16
}

export interface ISqureText {
	name: string
	color: string
	fontSize: number
	placement: IPlacementSquare
}

export interface IObjectText {
	name: string
	color: string
	fontSize: number
	placement: IPlacementSquare
}

export type IPlacementSquare =
	| 'center'
	| 'top-right'
	| 'top-left'
	| 'top-center'
	| 'bottom-right'
	| 'bottom-left'
	| 'bottom-center'
	| 'middle-right'
	| 'middle-left'
