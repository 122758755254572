import WhzoneAsyncSelect from '@/components/AsyncSelect/WhzoneAsyncSelect'
import { usePostRackViewQuery } from '@/hooks/rackView.hook'
import useToastStatus from '@/hooks/useToastStatus.hook'
import { IDtoPostRackView } from '@/types/rackView.types'
import { SaveOutlined } from '@ant-design/icons'
import {
	Button,
	Drawer,
	Form,
	Input,
	InputNumber,
	Modal,
	Space,
	Switch,
	message
} from 'antd'
import { FC } from 'react'
import { IoAddOutline } from 'react-icons/io5'

interface IProps {
	open?: boolean
	onClose?: () => void
}

const RackAdd: FC<IProps> = ({ onClose, open }) => {
	const [form] = Form.useForm<IDtoPostRackView>()

	const { mutate, isPending, isSuccess, error, isError, status } =
		usePostRackViewQuery()

	useToastStatus({ status })

	const onConfirm = async () => {
		try {
			const values = await form.validateFields()
			mutate(values)
			if (onClose) {
				onClose()
                window.location.reload()
			}
		} catch (error) {
			message.error('Ошибка! Заполните обязательные поля')
		}
	}

	return (
		<Drawer
			title='Добавление стеллажа'
			placement={'right'}
			closable={false}
			onClose={onClose}
			open={open}
			extra={
				<Button icon={<IoAddOutline />} type='primary' onClick={onConfirm}>
					Добавить
				</Button>
			}
		>
			<Form form={form} layout='vertical' className='font-light mt-3'>
				<Form.Item
					label='Наименование'
					name='rackname'
					rules={[
						{ required: true, message: 'Пожалуйста, введите наименование!' }
					]}
				>
					<Input size='large' placeholder='Введите наименование' />
				</Form.Item>
				<Form.Item label='Кол-во колонок' name='colnumber'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите кол-во колонок'
					/>
				</Form.Item>
				<Form.Item label='Кол-во ярусов' name='rownumber'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите кол-во ярусов'
					/>
				</Form.Item>
				<Form.Item label='X Координата' name='xcoord'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите X координату'
					/>
				</Form.Item>
				<Form.Item label='Y Координата' name='ycoord'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите Y координату'
					/>
				</Form.Item>
				<Form.Item label='Поворот' name='rotated'>
					<InputNumber
						size='large'
						className='w-full'
						min={0}
						placeholder='Введите поворот'
					/>
				</Form.Item>
				<Form.Item label='Зона' name='zone'>
					<WhzoneAsyncSelect
						size='large'
						placeholder='Выберите зону'
						onChange={value => form.setFieldsValue({ zone: value })}
						value={form.getFieldValue('zone')}
					/>
				</Form.Item>
				<div className='grid grid-cols-2 gap-3 col-span-2'>
					<Form.Item label='Доступен' name='available' valuePropName='checked'>
						<Switch />
					</Form.Item>
					<Form.Item label='Пропускать' name='skip' valuePropName='checked'>
						<Switch />
					</Form.Item>
					<Form.Item
						label='Показывать на карте'
						name='invisible'
						valuePropName='checked'
					>
						<Switch />
					</Form.Item>
				</div>

				{isError && (
					<Form.Item>
						<p className='text-red-500 text-xs font-light'>{error?.message}</p>
					</Form.Item>
				)}
			</Form>
		</Drawer>
	)
}

export default RackAdd
